<template>
    <div id="exams" class="exams">
        <SettingsHead class="exams__head">
            <div class="exams__buttons">
                <PocketLink
                    class="exams__buttons-activate"
                    type="tertiary"
                    :is-dark-mode="isDarkMode"
                    @click="showActivateLicenseSidePanel = true"
                >
                    + Activate License Code
                </PocketLink>
                <PocketButton
                    class="exams__buttons-add"
                    :is-dark-mode="isDarkMode"
                    @click="showAddExamSidePanel = true"
                >
                    Add Exam
                </PocketButton>
            </div>
        </SettingsHead>
        <AddExamSidePanel
            v-if="showAddExamSidePanel"
            key="exams"
            @close="showAddExamSidePanel = false"
        />
        <ActivateLicenseSidePanel v-if="showActivateLicenseSidePanel" @close="showActivateLicenseSidePanel = false" />
        <EditExamSidePanel
            v-if="editExamId && showEditExamSidePanel"
            :key="editExamId"
            :exam-id="editExamId"
            @close="showEditExamSidePanel = false; editExamId = null"
        />
        <div
            v-for="bundle in bundles"
            :key="bundle.objectId"
            class="exams__bundle"
        >
            <div class="exams__bundle-head">
                <div class="exams__bundle-head-icon">
                    <BundleIcon :bundle-id="bundle.objectId" :theme="isDarkMode ? 'silver' : 'color'" />
                </div>
                <div class="exams__bundle-head-right">
                    <h2 class="exams__bundle-head-title">
                        {{ bundle.name }} Pocket Prep
                        <Tag
                            v-if="bundleData[bundle.objectId].isPremium && !bundleData[bundle.objectId].isFreeBundle"
                            :is-dark-mode="isDarkMode"
                            class="exams__bundle-head-premium"
                            size="small"
                        >
                            <template #tagMessage>
                                Premium
                            </template>
                        </Tag>
                    </h2>
                    <template v-if="bundleData[bundle.objectId].isPremium">
                        <div v-dark class="exams__bundle-head-info">
                            Access to {{ bundleData[bundle.objectId].uniqueExams.length }}
                            {{ bundleData[bundle.objectId].uniqueExams.length > 1 ? 'exams' : 'exam' }},
                            all quiz modes, full question bank, and <PocketLink
                                type="tertiary-small"
                                href="https://help.pocketprep.com/en/articles/3697209-what-is-the-pass-guarantee"
                                target="_blank"
                                :is-dark-mode="isDarkMode"
                            >
                                pass guarantee.
                            </PocketLink>
                        </div>
                    </template>
                    <template v-else-if="bundleData[bundle.objectId].activeUniqueExams.length === 1">
                        <div v-dark class="exams__bundle-head-info">
                            Free access to three of six quiz modes and
                            {{ bundleData[bundle.objectId].activeUniqueExams[0].specialQuestions }} questions from the
                            full question bank.
                        </div>
                    </template>
                </div>
            </div>
            <template v-if="bundleData[bundle.objectId].isPremium">
                <div
                    v-if="breakpoint === 'black-bear'"
                    v-dark
                    class="exams__bundle-head-info"
                    :class="{
                        'exams__bundle-head-info--mobile': breakpoint === 'black-bear'
                    }"
                >
                    Access to {{ bundleData[bundle.objectId].uniqueExams.length }}
                    {{ bundleData[bundle.objectId].uniqueExams.length > 1 ? 'exams' : 'exam' }}, all quiz modes,
                    full question bank, and <PocketLink
                        type="tertiary-small"
                        href="https://help.pocketprep.com/en/articles/3697209-what-is-the-pass-guarantee"
                        target="_blank"
                        :is-dark-mode="isDarkMode"
                    >
                        pass guarantee.
                    </PocketLink>
                </div>
            </template>
            <template v-else-if="bundleData[bundle.objectId].activeUniqueExams.length === 1">
                <div
                    v-if="breakpoint === 'black-bear'"
                    v-dark
                    class="exams__bundle-head-info"
                    :class="{
                        'exams__bundle-head-info--mobile': breakpoint === 'black-bear'
                    }"
                >
                    Free access to three of six quiz modes and
                    {{ bundleData[bundle.objectId].activeUniqueExams[0].specialQuestions }} questions from the
                    full question bank.
                </div>
            </template>
            <div class="exams__exam-cards">
                <div
                    v-for="card in bundleData[bundle.objectId].examTableRows"
                    :key="`card-${card.id}`"
                    v-dark
                    class="exams__exam-card"
                >
                    <div class="exams__exam-card-name">
                        {{ card.exam }}
                    </div>
                    <div v-dark class="exams__exam-card-info">
                        <div class="exams__exam-card-info-access">
                            <div v-dark class="exams__exam-card-info-label">
                                Access
                            </div>
                            <div class="exams__exam-card-info-value">
                                {{ card.access }}
                            </div>
                        </div>
                        <div class="exams__exam-card-info-progress">
                            <div v-dark class="exams__exam-card-info-label">
                                Progress
                            </div>
                            <div class="exams__exam-card-info-value">
                                {{ getAnsweredFromExamGuid(String(card.examGuid)) }}
                                /
                                {{ getTotalFromExamGuid(String(card.examGuid)) }}
                            </div>
                        </div>
                    </div>
                    <PocketLink
                        class="exams__exam-card-settings"
                        type="tertiary-small"
                        :is-dark-mode="isDarkMode"
                        @click="clickExamSettings(String(card.id))"
                    >
                        Exam Settings
                    </PocketLink>
                </div>
            </div>
            <Table
                v-if="!isLoading"
                class="exams__bundle-exams"
                theme="open"
                :rows="bundleData[bundle.objectId].examTableRows"
                :columns="examTableColumns"
                :show-header="false"
                :table-row-styles="{ padding: '10px 18px 10px 11px' }"
                :table-column-labels-styles="{ padding: '5px 18px 5px 11px' }"
                :is-dark-mode="isDarkMode"
                @sort="updateSortSettings($event, bundle)"
            >
                <template #tableCellValue="{ row, column }">
                    <template v-if="column.propName === 'action'">
                        <PocketLink
                            type="tertiary-small"
                            :is-dark-mode="isDarkMode"
                            @click="clickExamSettings(row.id)"
                        >
                            Exam Settings
                        </PocketLink>
                    </template>
                    <template v-if="column.propName === 'progress'">
                        <div class="exams__progress">
                            <div class="exams__progress-fraction">
                                {{ getAnsweredFromExamGuid(row.examGuid) }}
                                <span>/</span>
                                {{ getTotalFromExamGuid(row.examGuid) }}
                            </div>
                            <div v-dark class="exams__progress-bar">
                                <div 
                                    v-dark
                                    class="exams__progress-bar-inner"
                                    :style="{ width: `${
                                        progressByExamGuid 
                                            ? calculateProgressPercent(row.examGuid)
                                            : 0
                                    }%` }"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        {{ row[column.propName] }}
                    </template>
                </template>
            </Table>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import AddExamSidePanel from '@/components/Settings/AddExamSidePanel.vue'
import ActivateLicenseSidePanel from '@/components/Settings/ActivateLicenseSidePanel.vue'
import EditExamSidePanel from '@/components/Settings/EditExamSidePanel.vue'
import { examMetadataModule } from '@/store/examMetadata/module'
import UIKit from '@pocketprep/ui-kit'
import type { ITableSortSettings, TTableRow } from '@pocketprep/ui-kit'
import { bundleModule } from '@/store/bundle/module'
import { questionModule } from '@/store/question/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { subscriptionModule } from '@/store/subscription/module'
import SettingsHead from '@/components/Settings/SettingsHead.vue'
import type { Study } from '@pocketprep/types'
import { progressModule } from '@/store/progress/module'
import { quizModule } from '@/store/quiz/module'
import { screenModule } from '@/store/screen/module'
import { difference } from '@/utils'

type TBundleData = {
    isPremium: boolean
    uniqueExams: Study.Class.ExamMetadataJSON[]
    activeUniqueExams: Study.Class.ExamMetadataJSON[]
    examTableRows: TTableRow[]
    isFreeBundle: boolean
}

@Component({
    components: {
        AddExamSidePanel,
        ActivateLicenseSidePanel,
        EditExamSidePanel,
        PocketButton: UIKit.Button,
        SettingsHead,
        BundleIcon: UIKit.BundleIcon,
        Table: UIKit.Table,
        PocketLink: UIKit.Link,
        Tag: UIKit.Tag,
    },
})
export default class Exams extends Vue {
    isLoading = true
    showAddExamSidePanel = false
    showActivateLicenseSidePanel = false
    showEditExamSidePanel = false
    editExamId: null | string = null
    examTableSortSettings: Partial<{ [bundleId: string]: ITableSortSettings }> = {}
    examTableColumns = [{
        name: 'Exam',
        propName: 'exam',
    }, {
        name: 'Access',
        propName: 'access',
    }, {
        name: 'Progress',
        propName: 'progress',
    }, {
        name: 'Action',
        propName: 'action',
        isLabelHidden: true,
        isSortDisabled: true,
        styles: {
            overflow: 'visible',
        },
    }]

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get userExamMetadata () {
        return userExamMetadataModule.getters.getUserExamMetadata()
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get bundles () {
        const examMetadata = examMetadataModule.getters.getExamMetadata()
        const examMetadataById = examMetadataModule.getters.getMostRecentExamMetadataById()
        const examGuids = this.userExamMetadata.map(uem =>
            examMetadata.find(e => e.examGuid === uem.examGuid)?.examGuid
        ).filter(examGuid => examGuid)
        return [ ...bundleModule.getters.getBundles() ]
            .filter(b => {
                const bExamGuids = b.exams.map(e => examMetadataById[e.objectId]?.examGuid)
                return difference(examGuids, bExamGuids).length !== examGuids.length
            })
            .sort((a, b) => a.name.localeCompare(b.name))
    }

    get bundleData () {
        return this.bundles.reduce<{ [bundleId: string]: TBundleData }>((acc, bundle) => {
            const uniqueExams = bundleModule.getters.getBundleUniqueExams(bundle.objectId)
            const activeUniqueExams = uniqueExams.filter(e =>
                userExamMetadataModule.getters.getUserExamMetadataForExamGuid(e.examGuid)
            )
            const examTableRows = [ ...activeUniqueExams ]
                .map(e => {
                    const examSubscription = subscriptionModule.getters.getSubscriptionForExamId(e.objectId)
                    const receipt = 
                        (typeof examSubscription !== 'boolean') && examSubscription.receipt as Study.Class.ReceiptJSON
                    let access = 'Free Prep'
                    if (examSubscription && receipt) {
                        access = receipt.source === 'Grandfather' ? 'Legacy Premium' : 'Premium Prep'
                    }

                    return {
                        id: e.objectId,
                        exam: e.nativeAppName,
                        examGuid: e.examGuid,
                        access,
                    }
                })
                .sort((a, b) => {
                    const sort = this.examTableSortSettings[bundle.objectId]

                    if (sort?.column?.propName === 'exam') {
                        return sort.direction * a.exam.localeCompare(b.exam)
                    }
                    if (sort?.column?.propName === 'access') {
                        return sort.direction * a.access.localeCompare(b.access)
                    }
                    if (sort?.column?.propName === 'progress') {
                        const aProgress = this.getAnsweredFromExamGuid(a.examGuid)
                            ? this.getAnsweredFromExamGuid(a.examGuid) / this.getTotalFromExamGuid(a.examGuid)
                            : 0.1 / this.getTotalFromExamGuid(a.examGuid)
                        const bProgress = this.getAnsweredFromExamGuid(b.examGuid)
                            ? this.getAnsweredFromExamGuid(b.examGuid) / this.getTotalFromExamGuid(b.examGuid)
                            : 0.1 / this.getTotalFromExamGuid(b.examGuid)
                        return sort.direction === -1
                            ? aProgress > bProgress
                                ? -1
                                : 1
                            : bProgress > aProgress
                                ? -1
                                : 1
                    }

                    return 0
                })

            acc[bundle.objectId] = {
                isPremium: subscriptionModule.getters.premiumBundleIds().includes(bundle.objectId)
                    || this.hasAllFreeExams(bundle),
                uniqueExams,
                activeUniqueExams,
                examTableRows,
                isFreeBundle: false,
            }

            // Checking to see is a bundle isFree. This allows variable check when adding
            // the "Premium" pill next to the Bundle name. Free bundle - don't add premium pill
            if (acc[bundle.objectId]?.isPremium) {
                const allFreeExams = acc[bundle.objectId]?.activeUniqueExams.every(exam => exam.isFree === true)
                if (allFreeExams) {
                    acc[bundle.objectId]['isFreeBundle'] = true
                }
            }
            return acc
        }, {})
    }

    get bestAnswers () {
        return Object.values(
            quizModule.getters.getLatestAnswers({ questionFilter: this.hasActiveSubscription ? 'all' : 'free' })
        )
    }

    get progressByExamGuid () {
        return {
            ...progressModule.getters.getProgressByExamGuid(),
            [this.currentExamMetadata?.examGuid || '']: {
                total: this.hasActiveSubscription
                    ? (this.currentExamMetadata?.itemCount || 0) - (this.currentExamMetadata?.archivedCount || 0)
                    : this.currentExamMetadata?.specialQuestions,
                answered: this.bestAnswers.length,
            },
        }
    }

    async mounted () {
        if (typeof this.$route.query.license === 'string') {
            this.showActivateLicenseSidePanel = true
        }

        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
            userModule.actions.fetchUserData(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            progressModule.actions.fetchProgress(),
        ])
        this.examTableSortSettings = bundleModule.getters.getBundles()
            .reduce<Partial<{ [bundleId: string]: ITableSortSettings}>>((acc, b) => {
            acc[b.objectId] = undefined
            return acc
        }, {})

        const settingsHeaderEl = document.getElementById('settings')
        // Scroll into view the top of the page on the setting header
        if (settingsHeaderEl && this.breakpoint !== 'black-bear') {
            settingsHeaderEl.scrollIntoView()
        }

        this.isLoading = false
    }

    hasAllFreeExams (bundle: Study.Class.BundleJSON) {
        const examMetadata = examMetadataModule.getters.getExamMetadataById()

        // searches through exams for any that isn't free
        return !bundle.exams.find(e => !examMetadata[e.objectId]?.isFree)
    }

    clickExamSettings (examId: string) {
        this.editExamId = examId
        this.showEditExamSidePanel = true
    }

    updateSortSettings (settings: ITableSortSettings, bundle: Study.Class.BundleJSON) {
        this.examTableSortSettings[bundle.objectId] = settings
    }

    getTotalFromExamGuid (examGuid: string) {
        return (
            this.progressByExamGuid
            && this.progressByExamGuid[examGuid] 
            && this.progressByExamGuid[examGuid]?.total
        ) || 0
    }

    getAnsweredFromExamGuid (examGuid: string) {
        return (
            this.progressByExamGuid
            && this.progressByExamGuid[examGuid]
            && this.progressByExamGuid[examGuid]?.answered
        ) || 0
    }

    calculateProgressPercent (examGuid: string) {
        const answered = this.getAnsweredFromExamGuid(examGuid)
        const total = this.getTotalFromExamGuid(examGuid)
        return Math.round((answered / total) * 100)
    }
}
</script>
<style lang="scss" scoped>
.exams {
    font-size: 16px;

    &__head {
        @include breakpoint(black-bear) {
            margin-bottom: 46px;
        }
    }

    &__buttons {
        position: absolute;
        top: 0;
        right: -12px;

        @include breakpoint(black-bear) {
            position: initial;
        }
    }

    &__buttons-activate {
        margin-right: 36px;

        @include breakpoint(black-bear) {
            margin-top: 24px;
            display: block;
        }
    }

    &__buttons-add {
        @include breakpoint(black-bear) {
            position: absolute;
            top: 0;
            right: -12px;
        }
    }

    &__content-label {
        font-size: 26px;
        line-height: 34px;
        font-weight: 600;
        margin-bottom: 32px;
        text-transform: capitalize;
    }

    &__bundle {
        margin-bottom: 51px;
        margin-left: -11px;
        margin-right: -12px;

        @include breakpoint(black-bear) {
            margin-left: 0;
            margin-right: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__bundle-head {
        display: flex;
        margin-bottom: 2px;

        @include breakpoint(black-bear) {
            margin-bottom: 12px;
        }
    }

    &__bundle-head-premium {
        vertical-align: middle;

        @include breakpoint(black-bear) {
            width: 57px;
            display: block;
            margin-left: 0;
            margin-top: 2px;
        }
    }

    &__bundle-head-icon {
        min-width: 63px;
        min-height: 63px;
        width: 63px;
        height: 63px;
        margin-right: 8px;

        @include breakpoint(black-bear) {
            min-width: 56px;
            min-height: 56px;
            width: 56px;
            height: 56px;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__bundle-head-right {
        margin-top: 12px;

        @include breakpoint(black-bear) {
            margin-top: 9px;
        }
    }

    &__bundle-head-title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        margin: 0 0 1px;
    }

    &__bundle-head-info {
        font-size: 13px;
        line-height: 18px;
        color: $slate-01;
        margin-bottom: 29px;

        @include breakpoint(black-bear) {
            margin-bottom: 0;
            display: none;
        }

        &--mobile {
            margin-bottom: 16px;

            @include breakpoint(black-bear) {
                display: block;
            }
        }

        &--dark {
            color: rgba($white, 0.82);
        }

        a {
            font-size: 13px;
            font-weight: 500;
        }
    }

    &__exam-cards {
        display: none;
        box-sizing: border-box;

        @include breakpoint(black-bear) {
            display: block;
        }
    }

    &__exam-card {
        background: $white;
        box-shadow: 0 1px 6px 0 $gray-divider;
        padding: 14px 0;
        max-width: 355px;
        width: 100%;
        border-radius: 6px;
        margin-bottom: 12px;
        box-sizing: border-box;

        @include breakpoint(black-bear) {
            max-width: 379px;
            width: calc(100% + 24px);
            margin: 0 -12px 12px;
            padding: 12px 0 0;
            border: 1px solid $gray-divider;
        }

        &--dark {
            background: $brand-black;
            box-shadow: 0 1px 6px 0 rgba($jet, 0.3);
            border-color: $brand-black;
        }
    }

    &__exam-card-name {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 12px;
        padding: 0 12px;
        font-weight: 600;
    }

    &__exam-card-info {
        border-bottom: 1px solid $gray-divider;
        display: flex;
        justify-content: space-between;
        padding: 0 12px;

        &--dark {
            border-bottom-color: $pickled-bluewood;
        }
    }

    &__exam-card-info-access {
        margin-bottom: 12px;
    }

    &__exam-card-info-label {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 4px;
        letter-spacing: 0.25px;
        color: $ash;

        &--dark {
            color: $fog;
        }
    }

    &__exam-card-info-value {
        font-size: 16px;
        line-height: 15px;
        min-width: 122px;
    }

    &__exam-card-settings {
        padding: 10px 12px;
        display: block;
    }

    &__bundle-exams {
        margin-left: 58px;

        @include breakpoint(black-bear) {
            display: none;
        }

        :deep(.uikit-table__column-label),
        :deep(.uikit-table__cell) {
            &:nth-child(1) {
                width: 208px;

                @include breakpoint(grizzly-bear) {
                    width: 190px;
                }
            }

            &:nth-child(2) {
                width: 170px;

                @include breakpoint(grizzly-bear) {
                    width: 159px;
                }
            }

            &:nth-child(3) {
                width: 176px;

                @include breakpoint(grizzly-bear) {
                    width: 138px;
                }
            }

            &:nth-child(4) {
                width: 93px;
                margin-left: 23px;

                @include breakpoint(grizzly-bear) {
                    margin-left: 15px;
                }
            }
        }
    }

    &__progress {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__progress-fraction {
        span {
            color: $pewter;
        }
    }

    &__progress-bar {
        position: relative;
        border-radius: 2px;
        width: 94px;
        height: 14px;
        background: $pearl;

        @include breakpoint(grizzly-bear) {
            width: 58px;
        }

        &--dark {
            background: $pickled-bluewood;
        }
    }

    &__progress-bar-inner {
        background: $brand-black;
        border-radius: 2px;
        height: 14px;
        position: absolute;
        left: 0;
        top: 0;

        &--dark {
            background: $pewter;
        }
    }
}
</style>